import { render, staticRenderFns } from "./Confirm.vue?vue&type=template&id=2fdea1cc&scoped=true&"
import script from "./Confirm.vue?vue&type=script&lang=js&"
export * from "./Confirm.vue?vue&type=script&lang=js&"
import style0 from "./Confirm.vue?vue&type=style&index=0&id=2fdea1cc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fdea1cc",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QSpace});
