<template>
  <div class="breadcrumbs">
    <BaseIcon
      name="mdi-home"
      inherit-color
      class="cursor-pointer icon"
      @click="$emit('reset')"
    />

    <div
      v-for="breadcrumb in breadcrumbs"
      :key="breadcrumb.id"
      class="breadcrumb"
      @click="$emit('click', breadcrumb)"
    >
      <BaseIcon name="eva-chevron-right" inherit-color class="icon q-mx-xs" />

      <div class="label">{{ breadcrumb.label }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Breadcrumbs",

  props: {
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
  user-select: none;

  .icon {
    color: var(--icon-color-inverted);
  }

  .breadcrumb {
    display: flex;
    align-items: center;

    .label {
      cursor: pointer;
      color: var(--secondary);
      font-weight: 500;
    }

    &:last-child {
      .label {
        color: var(--icon-color-inverted);
        font-weight: normal;
        cursor: default;
      }
    }
  }
}
</style>
