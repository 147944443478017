<template>
  <q-dialog :value="value" @input="close">
    <div id="confirm" :class="cssClass" :style="{ width }">
      <!-- header -->

      <div class="header">
        <BaseIcon
          :name="icon"
          :color="iconColor"
          :size="iconSize"
          class="q-mr-md"
        />

        <div class="title-1">
          <slot name="title"></slot>
        </div>

        <q-space />

        <BaseCloseButton v-if="closeIcon" @click="close" />
      </div>

      <!-- ... -->

      <!-- content -->

      <slot name="default"></slot>

      <!-- ... -->

      <!-- footer -->

      <div v-if="hasFooter" class="footer">
        <slot name="footer"></slot>
      </div>

      <!-- ... -->
    </div>
  </q-dialog>
</template>

<script>
export default {
  name: "Confirm",

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    width: {
      type: String,
      default: "480px",
    },

    icon: {
      type: String,
      required: true,
    },

    iconColor: {
      type: String,
      default: "secondary",
    },

    iconSize: {
      type: String,
      default: "20px",
    },

    hasFooter: {
      type: Boolean,
      default: true,
    },

    cssClass: {
      type: String,
      default: "",
    },

    closeIcon: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    close() {
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss" scoped>
#confirm {
  background-color: var(--component-bg-color);
  border-radius: 4px;
  padding: 16px;

  .header {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--divider-color);
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 16px;
    border-top: 1px solid var(--divider-color);
  }
}
</style>
